import React from "react"
import { Col, Container, Row, Button } from "react-bootstrap"

import Modal from "./bootstrap/modal"

export default function TermModal(props) {
  const { onClose } = props

  return (
    <Modal
      title={"Terms of Use"}
      onClose={onClose}
      show
      footer={
        <Row>
          <Col xs="auto" className="ml-auto"></Col>
          <Col xs="auto">
            <Button variant="darkblue" type="button" onClick={() => onClose()}>
              {"Close"}
            </Button>
          </Col>
        </Row>
      }
    >
      <Container fluid className="terms">
        <Row>
          <Col>
            <h2>
              <strong>Superloop Wi-Fi SERVICES - CONDITIONS OF USE</strong>
            </h2>
            <p>
              This agreement together with any additional terms, conditions, notices, and disclaimers
              contained elsewhere on Superloop WiFi form the conditions of supply of material on, use of
              and access to Superloop WiFi. Your continued use of Superloop WiFi constitutes an
              affirmative, acknowledgment and agreement by you that you accept and agree to abide and
              be bound by these conditions and any modifications.
            </p>
            <p>
              Should you object to any of these conditions or any subsequent modifications your only
              recourse is to immediately discontinue your use of Superloop WiFi.
            </p>
            <p>
              The additional conditions for these sites are set out separately below.
            </p>
            <p>
              From time to time, Superloop WiFi may run competitions, prize draws and promotions on
              Superloop WiFi. These will be subject to additional terms and conditions, which will be
              made known to you at the time.
            </p>
            <p>
              All terms and conditions that govern your use of Superloop WiFi (including disclaimers) may
              be amended at any time at the sole discretion of Superloop WiFi.
            </p>

            <p>
              <strong>
                ACCURACY OF INFORMATION AND DISCLAIMER OF WARRANTIES
              </strong>
            </p>

            <p>
              All material on Superloop WiFi is provided in good faith. It is derived from sources believed
              to be accurate and current as at the date of publication. Your use of Superloop WiFi is at
              your sole risk. Access to Superloop WiFi internet or Superloop WiFi Services is provided on
              an 'as available' basis. Neither Superloop WiFi, its related bodies corporate nor its or their
              directors or employees make any representation or warranty that (i) any material on
              Superloop WiFi will be reliable, accurate or complete (nor do they accept any responsibility
              arising in any way for errors in, or omissions from that material); or (ii) your access to
              Superloop WiFi will be uninterrupted, timely or secure. Superloop WiFi will not be liable for
              loss resulting from any action or decision by you in reliance on the information on
              Superloop WiFi, nor any interruption, delay in operation or transmission, virus,
              communications failure, Internet access difficulties, or malfunction in equipment or
              software.
            </p>

            <p>
              <strong>ONLINE SECURITY</strong>
            </p>

            <p>
              You accept that you are fully, wholly and solely responsible for your internet and online
              security.
            </p>

            <p>
              <strong>GENERAL INFORMATION ONLY - NON ADVISORY</strong>
            </p>

            <p>
              The material on Superloop WiFi provides general information only. It is not intended as
              advice and must not be relied upon as such. You should make your own inquiries and take
              independent advice tailored to your specific circumstances prior to making any decisions.
            </p>

            <p>
              <strong>TRADEMARKS</strong>
            </p>

            <p>
              The Superloop WiFi logo are registered trademarks of Superloop WiFi. Other trademarks
              may be displayed on Superloop WiFi from time to time. These may belong to third parties.
              Nothing displayed on Superloop WiFi should be construed as granting any license or right of
              use of any logo, masthead or trademark displayed on a website, without the express written
              permission of the relevant owner.
            </p>

            <p>
              <strong>
                YOUR CONDUCT
              </strong>
            </p>

            <p>
              As a condition of your use of Superloop WiFi Services or any services on Superloop WiFi, you
              warrant to Superloop WiFi that you will not use Superloop WiFi Services for any purpose
              that is unlawful or prohibited by these terms & conditions or any other terms, conditions or
              notices appearing anywhere on Superloop WiFi. You agree to abide by all applicable laws
              and regulations.
            </p>

            <p>In particular, you agree not to:</p>

            <ul>
              <li>
                use Superloop WiFi Services to defame, abuse, harass, stalk, threaten or otherwise
                offend others;
              </li>
              <li>
                publish, distribute, e-mail, transmit or disseminate any material which is unlawful,
                obscene, defamatory, indecent, offensive or inappropriate;
              </li>
              <li>
                use any automated scripting tools or software;
              </li>
              <li>
                engage in or promote any surveys, contests, pyramid schemes, chain letters,
                unsolicited e-mailing or spamming via Superloop WiFi Services;
              </li>
              <li>
                impersonate any person or entity;
              </li>
              <li>
                upload, post, e-mail, transmit or otherwise make available using Superloop WiFi
                Services any material that you do not have a right to make available under any law or
                contractual obligation or which contains viruses, or other computer codes, files or programs
                designed to interrupt, limit or destroy the functionality of other computer software or
                hardware;
              </li>
              <li>
                breach any applicable laws or regulations.
              </li>
            </ul>
            <p>
              Superloop WiFi retains the right at all times to monitor, retain and disclose any information
              as necessary to satisfy any applicable law, regulation, legal process or governmental
              request.
            </p>
            <p>
              You will indemnify Superloop WiFi if Superloop WiFi or its related bodies corporate suffer
              any loss or damage or incur any costs in connection with any breach of these conditions or
              any other legal obligation by you or your use of or conduct on Superloop WiFi.
            </p>

            <p>
              <strong>
                TAXES
              </strong>
            </p>

            <p>
              Unless stated to be otherwise, charges referred to for use of any services provided by
              Superloop WiFi via Superloop WiFi Services are inclusive of sales taxes pertinent to the legal
              jurisdiction in which the service is being used
            </p>

            <p>
              <strong>
                THIRD PARTY LINKS AND ADVERTISING
              </strong>
            </p>

            <p>
              Hyperlinks and pointers to websites operated by third parties will appear on Superloop WiFi
              from time to time. These websites do not form part of Superloop WiFi and are not under the
              control of Superloop WiFi or its related bodies corporate and neither Superloop WiFi nor its
              related bodies corporate have any responsibility for the contents of any such hyperlink or
              linked website. If you link to any such websites you leave Superloop WiFi and do so entirely
              at your own risk.
            </p>

            <p>
              Superloop WiFi displays third party advertisements, which may or may not contain
              hyperlinks or referral buttons to websites operated by third parties. The display of such
              advertising does not in any way imply a recommendation or endorsement by Superloop
              WiFi or its related bodies corporate of the relevant advertiser, its products or services or any
              such linked website.
            </p>

            <p>
              If you contact a third party using functionality provided on Superloop WiFi, including via email, Superloop WiFi accepts no responsibility for any actions taken by that third party in
              response to your communication or for any transactions entered into between you and the
              relevant third party.
            </p>

            <p>
              <strong>
                LIMITATION OF LIABILITY
              </strong>
            </p>
            
            <p>
              Superloop WiFi does not exclude any rights and remedies available to you under the Trade
              Practices Act (Cth, Australia) or any similar legislation pertinent to the country in which the
              service is being used that cannot be excluded, restricted or modified. Otherwise, Superloop
              WiFi and its related bodies corporate exclude all conditions and warranties that may be
              implied by law. To the extent permitted by law, Superloop WiFi's liability for breach of any
              implied warranty or condition which cannot be excluded is restricted, at Superloop WiFi's
              option, to:
            </p>

            <p>
              in the case of services supplied or offered by Superloop WiFi:
            </p>
            <ul>
              <li>
                the re-supply of those services; or
              </li>
              <li>
                the payment of the cost of having those services re-supplied; and
              </li>
            </ul>

            <p>
              in the case of goods supplied or offered by Superloop WiFi:
            </p>
            <ul>
              <li>
                the replacement of the goods or the supply of equivalent goods;
              </li>
              <li>
                the repair of the goods;
              </li>
              <li>
                the payment of the cost of having the goods replaced; or
              </li>
              <li>
                the payment of the cost of having the goods repaired.
              </li>
            </ul>
          
            <p>
              You expressly agree and understand that in no circumstances will Superloop WiFi or its
              related bodies corporate be liable to you for any indirect, incidental, special and/or
              consequential losses or damages or loss of profits of whatever nature howsoever arising
              (including but not limited to any act or omission by Superloop WiFi or its related bodies
              corporate) which result from (i) the use of or access to or any inability to use or access
              Superloop WiFi or any material on Superloop WiFit; (ii) unauthorised access to or alterations
              of your transmissions or data; or (iii) statements or conduct of any third party on Superloop
              WiFi. Excluding liability for negligence, the maximum liability of Superloop WiFi and its
              related bodies corporate is equivalent to the total of any amounts you have paid to 
            </p>

            <p>
              Superloop WiFi and its related bodies corporate in respect of goods or services supplied to
              you by Superloop WiFi or its related bodies corporate through Superloop WiFi.
            </p>

            <p>
              <strong>
                LIMITATION OF OUR LIABILITY WHEN YOU USE YOUR OWN EQUIPMENT
              </strong>
            </p>

            <p>
              You expressly agree and understand that in no circumstances will Superloop WiFi or its
              related bodies corporate be liable to you for any damage to equipment owned by you and
              connected, in any manner whatsoever, by you to Superloop WiFi equipment or connected
              to Superloop WiFi equipment by any staff member or any other person at your request.
            </p>

            <p>
              You expressly agree and understand that in no circumstances will Superloop WiFi or its
              related bodies corporate be liable to you for any loss of data, including, but not limited to,
              photographs, moving pictures, documents, financial information or any data whatsoever, on
              any equipment owned by you and connected by you to Superloop WiFi equipment, in any
              manner whatsoever, or connected to Superloop WiFi equipment by any staff member or
              any other person at your request.
            </p>

            <p>
              <strong>
                APPLICABLE LAW
              </strong>
            </p>

            <p>
              These conditions and all other specific and additional terms which govern your use or
              membership of and access to Superloop WiFi will be governed by and construed in
              accordance with the laws of the state of New South Wales, Australia.
            </p>
          </Col>
        </Row>
      </Container>
    </Modal>
  )
}
