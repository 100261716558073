
import React, {useRef, useEffect} from "react";
import {useAsyncSetState} from "use-async-setstate";
import Formsy from "formsy-react";
import { Container, Row, Col } from "react-bootstrap";
import {fetch} from "window-or-global";
import fetchin from "../logic/utils/fetch";
import Manager from "../components/manager";
import Theme from "../components/theme";
import Button from "../components/bootstrap/button";
import FormsyTextField from "../components/formsy/text-field";
import config from "../config";
import Terms from "../components/tnc";
import root from "window-or-global";

const str = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}
export function generatePassword(s = str, len = 8) {
  let r = "";
  for (let i = 0; i < len; i++) {
    const idx = Math.round(getRandomArbitrary(0, s.length - 1));
    r += s[idx];
  }
  return r;
}


export default function LoginPage() {
  const [errorMessage, setErrorMessage] = useAsyncSetState(null);
  const [checked, setCheck] = useAsyncSetState(false);
  const [showTerm, setTerm] = useAsyncSetState(false);
  const [loginState, setLoginState] = useAsyncSetState(false);
  const [loading, setLoadingAsync] = useAsyncSetState(false);
  const authFormEl = useRef(null);
  const [creds, setCreds] = useAsyncSetState({
    username: "",
    password: generatePassword(),
    dst: ""
  });
  const [authUrl, setAuthUrl] = useAsyncSetState(config.authUrl);
  useEffect(() => {
    try {
      if (root.location) {
        const url = new URL(root?.location);
        if (url.searchParams.has("src")) {
          const srcLocation = atob(url.searchParams.get("src"));
          const srcUri = new URL(srcLocation);
          setAuthUrl(`https://${srcUri.host}/login`)
          console.log("custom target set", `https://${srcUri.host}/login`);
        }
      }
    } catch(err) {
      console.log("failed to set custom auth url", err);
    }
  }, []);

  
  async function checkIfLoggedInOnHotspot() {
    try {
      const text = await fetchin.get(config.loginStatus);
      console.log("data", text);
      const data = JSON.parse(text);
      if (data) {
        if (data.loggedIn === "yes") {
          root.location = config.targetUrl;
        }
      }
    } catch(err) {
      console.log("unable to talk to the hotspot", err);
    }
  }
  
  async function handleValidSubmit(data) {
    try {
      await setLoadingAsync(true);
      const {residency, ...rest} = data;
      const response = await fetch(`${config.apiUrl}rest.api/register`, {
        credentials: "include",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign({password: creds.password}, {
          ...data, 
        })),
      });
      switch(response.status) {
        case 503:
        case 401:
          await setErrorMessage(() => (<>
            {"An error has occurred"}
          </>));
        case 200:
          const result = await response.json();
          // console.log("results", results)
          const {userName} = result;

          setCreds({...creds, userName});
          await setLoginState(true);
          authFormEl.current.submit();
          // submit
      }
    } catch (err) {
      console.log("err", err);
      setLoadingAsync(false);
      setErrorMessage("An error has occurred");
    }
  }
  
  
  useEffect(() => {
    checkIfLoggedInOnHotspot();
  }, []);
  
  return (
    <Manager name="login" title="Register">
      <Theme>
        <form ref={authFormEl} action={authUrl} method="post">
          <input type="hidden" name="username" value={creds.userName} />
          <input type="hidden" name="password" value={creds.password} />
          <input type="hidden" name="dst" value={""} />
        </form>
        {
          !loginState ? 
          <Formsy onValidSubmit={(data) => handleValidSubmit(data)}>
            <Container fluid className="form-card-component form-spacer no-gutters">
              <div className="form-heading">
                <p className="form-heading-text">{"Please enter your details below"}</p>
              </div>
              <div className="field-row">
                <FormsyTextField
                  name={"firstName"}
                  className="form-field-input"
                  placeholder={'Your First Name'}
                  label={'First Name'}
                  suffix={<i className="fas fa-user-tie"></i>}
                  required
                />
              
              </div>
              <div className="field-row">
                <FormsyTextField
                  name={"lastName"}
                  placeholder={'Your Last Name'}
                  label={'Last Name'}
                  className="field-icon form-field-input"
                  required
                />
              </div>
              <div className="field-row">
                <FormsyTextField
                  name={"email"}
                  type="email"
                  label={'Email Address'}
                  placeholder={'Your Email'}
                  validations={"isEmail"}
                  className="form-field-input"
                  validationError="A valid email address is required"
                  suffix={<i className="field-icon fa fa-envelope" aria-hidden="true"></i>}
                  required
                />
                
              </div>
              <div className="form-row-component">
                  {showTerm && <Terms onClose={() => setTerm(false)} />}
                  <div className="vw-checkbox">
                    <i
                      onClick={async () => setCheck(!checked)}
                      style={{ fontSize: 16 }}
                      className={`mr-2 ${
                        checked ? "far fa-check-square" : "far fa-square"
                      }`}
                    />
                    <span className="agree-component-text">
                      {"I agree to the"}
                      <a onClick={() => setTerm(true)} className="mx-1">
                        {"terms of use"}
                      </a>
                      {"of this network."}
                    </span>
                  </div>
              </div>
          
              <div className="form-component-button">
                {
                  !checked ?
                    <Button
                      type={"submit"}
                      className="continue-button-disable"
                      disabled={true}
                    >
                      <span className="button-text">{"Continue"}</span> 
                      <img
                        src="/images/arrow-right.svg"
                        className="button-icon pl-3"
                        alt="arrow-right"
                      />
                    </Button>
                  :
                    loading ?
                      <Button
                        type={"submit"}
                        className={"continue-button-error"}
                          
                      >
                        <i className="fas fa-circle-notch fa-spin loading-icon"></i>
                      </Button>
                      :
                      <Button
                        type={"submit"}
                        className={errorMessage ? "continue-button-error" :"continue-button-active"}
                          
                      >
                        {errorMessage && !loading ?
                          <span className="error-message">
                            {errorMessage}
                          </span>
                          :
                          <>
                            <span className="button-text">
                              {"Continue"}
                            </span> 
                            <img
                              src="/images/arrow-right.svg"
                              className="button-icon pl-3"
                              alt="arrow-right"
                            />
                          </>
                      }
                    </Button>
                }     
              </div>
            </Container>
          </Formsy>
        :
          <Container fluid className="form-card-component form-spacer no-gutters">
            <div className="form-heading pt-5 pl-5 pr-5 pb-0">
              <p className="form-heading-text">{"You Successfully Logged In"}</p>
            </div>
            <div className="form-body pl-5 pr-5 pb-5">
              <a href="https://www.superloop.com/">
                <Button className="login-state-continue">{'Continue'}</Button>
              </a>
            </div>
          </Container>
        }
      </Theme>
    </Manager>
  );
}
